.serviceDetailsBg {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
}

.serviceDetails {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  background-color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 550px;
  width: 800px;
  border-radius: 20px;
  box-shadow: 2px 2px 10px 5px rgb(200, 200, 200);
}

/* Scrollbar design */
.serviceDetails ::-webkit-scrollbar {
  width: 15px;
}

/* Track */
.serviceDetails ::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.serviceDetails ::-webkit-scrollbar-thumb {
  background: #34b74a;
  border-radius: 10px;
}

/* Handle on hover */
.serviceDetails ::-webkit-scrollbar-thumb:hover {
  background: #308f3f;
}
/* Scrollbar design */

.serviceDetails img {
  height: 100px;
  width: 100px;
}

.serviceDetails h4 {
  font-size: 26px;
  color: #34b74a;
}

.serviceDetails h6 {
  height: 250px;
  width: 700px;
  overflow-y: auto;
  font-size: 18px;
  font-weight: 400;
}

.serviceDetails a {
  padding-bottom: 20px;
  font-size: 16px;
  font-weight: 600;
  font-style: italic;
  color: #34b74a;
}

.serviceDetails a:hover {
  text-decoration: underline;
}

.serviceDetails button {
  position: absolute;
  top: 10px;
  right: 10px;
}

.serviceDetails .icon {
  height: 30px;
  width: 30px;
  color: #34b74a;
}
@media (max-width: 840px) {
  .serviceDetails {
    margin-top: 40px;
    width: 90%;
    height: 80%;
  }

  .serviceDetails h4 {
    font-size: 22px;
  }

  .serviceDetails h6 {
    width: 90%;
    font-size: 16px;
  }
}

@media (max-width: 400px) {
  .serviceDetails {
    gap: 20px;
    width: 325px;
  }

  .serviceDetails h4 {
    font-size: 22px;
    color: #34b74a;
  }

  .serviceDetails h6 {
    width: 280px;
    font-size: 16px;
    font-weight: 400;
  }
}
