@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 200;
}

html, body {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
}
