.pdfBg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  z-index: 10;
}

.pdfContainer {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  bottom: 10px;
  left: 80px;
  height: fit-content;
  width: 300px;
  padding: 10px 5px;
  background-color: #fff;
  box-shadow: 0px 0px 10px 0px #c8c8c8;
  border-radius: 20px;
  color: #010252;
  animation: scale-up-bl 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.pdfContainer .pdfGroupSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #c8c8c8;
  font-size: 12px;
}

.pdfContainer .pdfGroupSection:hover {
  cursor: pointer;
}

.pdfContainer .pdfGroupSection .pdfBrand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-left: 10px;
  font-size: 12px;
}

.pdfContainer .pdfGroup {
  display: none;
  height: 0;
}

.pdfContainer .pdfGroup.showPdfGroup {
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.pdfContainer .pdfLink {
  display: flex;
  align-items: center;
  gap: 5px;
}

.pdfContainer .pdfLink a {
  width: 80%;
}

.pdfContainer .pdfLink:hover {
  background-color: #c8c8c8;
}

.pdfContainer div .pdfIcon {
  height: 20px;
  width: 20px;
  color: #010252;
}

@keyframes scale-up-bl {
  0% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform-origin: 0% 100%;
    transform-origin: 0% 100%;
  }
}

@media (max-width: 450px) {
  .pdfContainer {
    left: 65px;
    width: 250px;
  }

  .pdfContainer .pdfGroupSection {
    gap: 5px;
  }

  .pdfContainer a {
    width: 80%;
  }
}
