#kezdolap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("../../images/landing_bg.jpg");
  width: 100%;
  min-height: 100vh;
  color: #010252;
}

#kezdolap .landingContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 100px;
  min-height: calc(100vh - 180px);
}

#kezdolap .landingContent .landingTextImg {
  display: flex;
  align-items: center;
}

#kezdolap .landingContent .landingTextImg img {
  height: 300px;
  width: 300px;
}

#kezdolap .landingContent .landingTextImg .landingText {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

#kezdolap .landingContent .landingTextImg .landingText .landingTitle {
  width: 750px;
  font-size: 55px;
}

#kezdolap .landingContent .landingTextImg .landingText .landingSubtitle {
  width: 750px;
  font-size: 22px;
  font-style: italic;
  font-weight: 400;
}

#kezdolap .landingContent .contactInfoCard {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 120px;
  width: 350px;
  background-color: #fff;
  box-shadow: 2px 2px 10px 5px #c8c8c8;
  border-radius: 0;
  transition: 0.5s;
}

#kezdolap .landingContent .contactInfoCard.phoneInfoCard:hover {
  box-shadow: 0px 0px 5px 2px #010252;
  border-radius: 20px;
  transition: 0.5s;
}

#kezdolap .landingContent .contactInfoCard .icon {
  height: 60px;
  width: 60px;
  color: #010252;
}

#kezdolap .landingContent .contactInfoCard h3 {
  font-size: 22px;
  font-weight: 800;
}

#kezdolap .landingContent .contactInfoCard h5,
#kezdolap .landingContent .contactInfoCard a {
  font-size: 16px;
  font-weight: 500;
}

@media (max-width: 1500px) {
  #kezdolap {
    padding-top: 80px;
  }

  #kezdolap .landingContent {
    gap: 50px;
  }

  #kezdolap .landingContent .landingTextImg {
    flex-direction: column;
    gap: 40px;
  }

  #kezdolap .landingContent .landingTextImg img {
    height: 250px;
    width: 250px;
  }
}

@media (max-width: 1280px) {
  #kezdolap {
    padding-top: 100px;
  }

  #kezdolap .landingContent {
    flex-direction: column;
    gap: 70px;
    height: unset;
  }

  #kezdolap .landingContent .landingTextImg {
    text-align: center;
    gap: 10px;
  }

  #kezdolap .landingContent .landingTextImg img {
    height: 200px;
    width: 200px;
  }
}

@media (max-width: 820px) {
  #kezdolap nav {
    padding: 0 10px;
    font-size: 16px;
  }

  #kezdolap nav ul {
    gap: 50px;
  }

  #kezdolap .landingContent .landingTextImg .landingText .landingTitle,
  #kezdolap .landingContent .landingTextImg .landingText .landingSubtitle {
    text-align: center;
    width: 80%;
  }

  #kezdolap .landingContent .landingTextImg .landingText .landingTitle {
    font-size: 35px;
  }

  #kezdolap .landingContent .landingTextImg .landingText .landingSubtitle {
    font-size: 18px;
  }

  #kezdolap .landingContent .contactInfoCard {
    height: 100px;
    width: 250px;
  }

  #kezdolap .landingContent .contactInfoCard .icon {
    height: 40px;
    width: 40px;
  }

  #kezdolap .landingContent .contactInfoCard h3 {
    font-size: 18px;
  }

  #kezdolap .landingContent .contactInfoCard h5,
  #kezdolap .landingContent .contactInfoCard a {
    font-size: 12px;
  }
}

@media (max-width: 450px) {
  #kezdolap nav {
    gap: 20px;
    padding: 0 10px;
    font-size: 12px;
  }

  #kezdolap nav ul {
    gap: 20px;
  }

  #kezdolap .landingContent {
    gap: 20px;
  }

  #kezdolap .landingContent .landingTextImg .landingText .landingTitle {
    font-size: 22px;
  }

  #kezdolap .landingContent .landingTextImg .landingText .landingSubtitle {
    font-size: 14px;
  }

  #kezdolap .landingContent .landingTextImg img {
    height: 120px;
    width: 120px;
  }
}
