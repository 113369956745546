#kapcsolat {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  width: 100%;
  color: #010252;
}

#kapcsolat .contactInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 40vh;
  width: 100%;
}

#kapcsolat .contactInfo .title {
  text-transform: uppercase;
  color: #010252;
}

#kapcsolat .contactInfo .contactInfoCards {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
}

#kapcsolat .contactInfo .contactInfoCard {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  height: 150px;
  width: 350px;
  box-shadow: 2px 2px 10px 5px #c8c8c8;
  border-radius: 20px;
}

#kapcsolat .contactInfo .contactInfoCard .icon {
  height: 60px;
  width: 60px;
  color: #34b74a;
}

#kapcsolat .contactInfo .contactInfoCard h3 {
  font-size: 22px;
  font-weight: 800;
}

#kapcsolat .contactInfo .contactInfoCard h5,
#kapcsolat .contactInfo .contactInfoCard a {
  font-size: 16px;
  font-weight: 500;
}

#kapcsolat .formInfo {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  background-image: url("../../images/contact_bg.jpg");
  background-size: cover;
}

#kapcsolat .formInfo .servicesCard {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  gap: 20px;
  height: 50vh;
  width: 20%;
}

#kapcsolat .formInfo .servicesCard h3 {
  padding: 10px 20px;
}

#kapcsolat .formInfo .servicesCard h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 400px;
  background-color: #fff;
  box-shadow: 2px 2px 10px 5px #c8c8c8;
  border-radius: 10px;
  transition: 0.3s;
  font-size: 18px;
  font-weight: 500;
}

#kapcsolat .formInfo .servicesCard h5:hover {
  cursor: pointer;
  box-shadow: 0px 0px 10px 1px #010252;
}

#kapcsolat .formInfo form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 60vh;
  width: fit-content;
}

#kapcsolat .formInfo form div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

#kapcsolat .formInfo form input,
#kapcsolat form textarea,
#kapcsolat .formInfo form select {
  background-color: #fff;
  padding-left: 20px;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 10px;
  font-size: 18px;
  color: #010252;
}

#kapcsolat .formInfo form input {
  height: 60px;
  width: 350px;
}

#kapcsolat .formInfo form select {
  height: 60px;
  width: 350px;
  cursor: pointer;
}

#kapcsolat .formInfo form select.unselected {
  color: #c0c1c1;
}

#kapcsolat .formInfo form textarea {
  height: 120px;
  width: 720px;
  padding-top: 20px;
}

#kapcsolat .formInfo form .contactFormCheckbox {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 80%;
}

#kapcsolat .formInfo form .contactFormCheckbox input {
  height: 20px;
  width: 20px;
  padding-left: unset;
}

#kapcsolat .formInfo form .contactFormCheckbox label {
  font-size: 16px;
}

#kapcsolat .formInfo form .contactFormCheckbox label .linkToPrivacyPolicy {
  text-decoration: underline;
  cursor: pointer;
  color: #0000ee;
}

#kapcsolat .formInfo form input::placeholder,
#kapcsolat form textarea::placeholder {
  opacity: 0.5;
}

#kapcsolat .formInfo form button {
  background-color: #34b74a;
  height: 60px;
  width: 200px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
  transition: 0.5s;
}

#kapcsolat .formInfo form button:hover {
  background-color: #308f3f;
}

#kapcsolat .formInfo form .confirmationTxt {
  text-align: center;
}

@media (max-width: 1280px) {
  #kapcsolat .contactInfo .contactInfoCard {
    width: 250px;
  }

  #kapcsolat .contactInfo .contactInfoCard .icon {
    height: 40px;
    width: 40px;
  }

  #kapcsolat .contactInfo .contactInfoCard h3 {
    font-size: 18px;
  }

  #kapcsolat .contactInfo .contactInfoCard h5,
  #kapcsolat .contactInfo .contactInfoCard a {
    font-size: 12px;
  }
}

@media (max-width: 820px) {
  #kapcsolat {
    gap: 30px;
    padding: 20px 0;
  }

  #kapcsolat .contactInfo {
    gap: 30px;
    height: unset;
  }

  #kapcsolat .contactInfo .contactInfoCards {
    flex-direction: column;
    gap: 30px;
  }

  #kapcsolat .contactInfo .contactInfoCard div {
    width: 150px;
  }

  #kapcsolat .contactInfo .contactInfoCard {
    height: 80px;
    width: 220px;
  }

  #kapcsolat .contactInfo .contactInfoCard .icon {
    height: 35px;
    width: 35px;
  }

  #kapcsolat .contactInfo .contactInfoCard h3 {
    font-size: 14px;
  }

  #kapcsolat .contactInfo .contactInfoCard h5,
  #kapcsolat .contactInfo .contactInfoCard a {
    font-size: 10px;
  }

  #kapcsolat .formInfo form {
    height: unset;
  }

  #kapcsolat .formInfo form h2 {
    text-align: center;
  }

  #kapcsolat .formInfo form div {
    gap: 15px;
  }

  #kapcsolat .formInfo form input,
  #kapcsolat form textarea,
  #kapcsolat .formInfo form select {
    font-size: 16px;
  }

  #kapcsolat .formInfo form input,
  #kapcsolat .formInfo form select {
    height: 50px;
    width: 155px;
  }

  #kapcsolat .formInfo form textarea {
    height: 100px;
    width: 325px;
  }

  #kapcsolat .formInfo form .contactFormCheckbox label {
    font-size: 13px;
  }
}
