header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    width: 100%;
    padding: 0 50px;
    background-color: #010252;
    color: #fff;
    z-index: 10;
}

header .headerLocation {
    display: flex;
    justify-content: flex-start;
    width: 400px;
}

header nav ul {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 50px;
    font-weight: 700;
    width: 400px;
}

header nav ul li:hover {
    color: #9f9fc7;
}

header div {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

header div .links {
    text-align: center;
    padding: 5px 10px;
}

header div .links.selected {
    background-color: #54557e;
    border-radius: 10px;
}

header div .bugIcon {
    height: 25px;
    width: 25px;
    color: #fff;
}

@media (max-width: 1500px) {  

    header .headerLocation {
        display: none;
    }

    header nav ul {
        width: unset;
    }

}

@media (max-width: 1280px) {  
    
    header {
        font-size: 13px;
    }
    
    header div {
        gap: 10px;
    }

}

@media (max-width: 820px) {  

    header {
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        padding: unset;
    }
    
    header div h4 {
        font-size: 12px;
    }
}

@media (max-width: 450px) {  
    
    header nav ul {
        gap: 15px;
    }
    
    header div h4 {
        font-size: 12px;
    }
}