.ozoneServicesSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-image: url("../../images/contact_bg.jpg");
    min-height: 100vh;
    width: 100%;
    color: #010252;
}

.ozoneServicesSection .title {
    display: flex;
    justify-content: center;
    align-items: center;    
    height: 100px;
    text-transform: uppercase;
}

.ozoneServicesSection .ozoneServicesContent {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
    width: 90%;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesCard {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    width: 700px;
    height: 600px;
    padding: 20px;
    border-radius: 20px;
    box-shadow: 2px 2px 10px 5px rgb(200, 200, 200);
    transition: 1s;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesCard:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 10px 2px #7d7d7d;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions .ozoneServicesSolutionsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 50%;
    width: 90%;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions .ozoneServicesSolutionsContainer div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    gap: 20px;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions img {
    height: auto;
    width: 220px;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions .ozoneServicesSolutionsContainer h3 {
    text-align: center;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions .ozoneServicesSolutionsContainer h6 {
    max-height: 200px;
    font-weight: 400;
    width: 80%;
    overflow-y: auto;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions .ozoneServicesSolutionsContainer h6 .ozoneItalic {
    border: unset;
    font-style: italic;
    text-decoration: underline;
}

/* Scrollbar design */
.ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions ::-webkit-scrollbar {
    width: 15px;
}
  
/* Track */
.ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
}
   
/* Handle */
.ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions ::-webkit-scrollbar-thumb {
    background: #34b74a; 
    border-radius: 10px;
}
  
/* Handle on hover */
.ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions ::-webkit-scrollbar-thumb:hover {
    background: #308f3f; 
}
/* Scrollbar design */

.ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions span {
    width: 100%;
    border: 1px solid #010252;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesCustomers {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesCustomers div {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    height: 50%;
    width: 50%;
    padding: 10px;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesCustomers div:nth-child(1), .ozoneServicesSection .ozoneServicesContent .ozoneServicesCustomers div:nth-child(2) {
    border-right: 2px solid #010252;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesCustomers div:nth-child(1), .ozoneServicesSection .ozoneServicesContent .ozoneServicesCustomers div:nth-child(3) {
    border-bottom: 2px solid #010252;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesCustomers div img {
    height: 50%;
    width: 50%;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesCustomers div h5 {
    text-align: center;
}

.ozoneServicesSection .ozoneServicesContent .ozoneServicesCustomers div h6 {
    width: 90%;
    font-size: 12px;
    font-weight: 400;
    text-align: center;
}

@media (max-width: 1600px) {
    
    .ozoneServicesSection .ozoneServicesContent {
        flex-direction: column;
        gap: 100px;
    }

    .ozoneServicesSection .ozoneServicesContent .ozoneServicesCard:hover {
        transform: scale(1.1);
    }

}

@media (max-width: 820px) {
    .ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions .ozoneServicesSolutionsContainer div {
        max-height: 200px;
    }

    .ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions .ozoneServicesSolutionsContainer img {
        width: 150px;
    }

    .ozoneServicesSection .ozoneServicesContent .ozoneServicesCard {
        width: 90%;
        height: 600px;
        padding: 0;
    }

    .ozoneServicesSection .ozoneServicesContent .ozoneServicesCard:hover {
        transform: unset;
    }
    
    .ozoneServicesSection .ozoneServicesContent .ozoneServicesCustomers {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        height: unset;
    }

    .ozoneServicesSection .ozoneServicesContent .ozoneServicesCustomers div {
        height: unset;
        height: 400px;
        width: 80%;
        border-bottom: 2px solid #010252;
        border-right: unset !important;
    }

    .ozoneServicesSection .ozoneServicesContent .ozoneServicesCustomers div:nth-child(4) {
        border-bottom: unset;
    }

    .ozoneServicesSection .ozoneServicesContent .ozoneServicesCustomers div img {
        width: 200px;
    }

}

@media (max-width: 450px) { 
    .ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions .ozoneServicesSolutionsContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        height: 50%;
    }
    
    .ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions .ozoneServicesSolutionsContainer img {
        height: auto;
        width: 120px;
    }
    
    .ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions .ozoneServicesSolutionsContainer h6 {
        max-height: 120px;
        width: 90%;
    }
    
    .ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions span {
        width: 100%;
        border: 1px solid #010252;
    }

    .ozoneServicesSection .ozoneServicesContent .ozoneServicesSolutions .ozoneServicesSolutionsContainer div {
        gap: 5px;
    }
}