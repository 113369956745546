.pestChooseUsSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    width: 100%;
    color: #010252;
}

.pestChooseUsSection .content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 250px;
}

.pestChooseUsSection .content .contentSeparator {
    position: relative;
}

.pestChooseUsSection .content .contentSeparator.leftContent {
    display: flex;
    justify-content: center;
    align-items: center;
}

.pestChooseUsSection .content .contentSeparator button {
    position: absolute;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 10px;
    background-color: #fff;
    height: 40px;
    width: 280px;
    padding: 0 15px;
    font-size: 14px;
    box-shadow: 0 0 5px 2px #c8c8c8;
    border-radius: 30px;
    z-index: 3;
    transition: transform 0.5s;
}

.pestChooseUsSection .content .contentSeparator button .icon {
    height: 30px;
    width: 30px;
    color: #34b74a;
}

.pestChooseUsSection .content .contentSeparator .left {
    top: 30%;
    left: 0;
    transform: translateX(-50%);
}

.pestChooseUsSection .content .contentSeparator .right {
    bottom: 30%;
    right: 0;
    transform: translateX(50%);
}

.pestChooseUsSection .content .contentSeparator .left:hover {
    transform: translateX(-50%) scale(1.05);
}

.pestChooseUsSection .content .contentSeparator .right:hover {
    transform: translateX(50%) scale(1.05);
}

.pestChooseUsSection .content .contentSeparator .imgDiv {
    position: relative;
    height: 600px;
    width: 600px;
    border-radius: 50%;
}

.pestChooseUsSection .content .contentSeparator .imgDiv .aboutUs, .pestChooseUsSection .content .contentSeparator .imgDiv .toolsImg {
    position: absolute;
    height: 600px;
    width: 600px;
    border-radius: 50%;
    object-fit: cover;
    opacity: 1;
    transition: 1.5s;
}

.pestChooseUsSection .content .contentSeparator .imgDiv img.hide {
    opacity: 0;
}

.pestChooseUsSection .content .rightContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 500px;
    width: 600px;
}

.pestChooseUsSection .content .rightContent h2 {
    font-size: 30px;
    font-weight: 800;
}

.pestChooseUsSection .content .rightContent h3 {
    font-size: 20px;
    font-weight: 500;
}

.pestChooseUsSection .content .rightContent div {
    display: flex;
    gap: 20px;
    width: 100%;
}

.pestChooseUsSection .content .rightContent div .check {
    height: 40px;
    width: 40px;
    color: #34b74a;
}

.pestChooseUsSection .content .rightContent div .linkToDocs {
    text-decoration: underline;
    cursor: pointer;
    color: #0000EE;
}

.pestChooseUsSection .content .rightContent div h4 {
    width: 90%;
    font-weight: 400;
}

@media (max-width: 1700px) {  
    
    .pestChooseUsSection .content {
        gap: 80px;
    }

    .pestChooseUsSection .content .contentSeparator .left {
        top: 10%;
        left: 50%;
        transform: translateX(-50%);
    }

    .pestChooseUsSection .content .contentSeparator .right {
        bottom: 10%;
        right: 50%;
        transform: translateX(50%);
    }
}

@media (max-width: 1280px) {  
    .pestChooseUsSection .content {
        flex-direction: column;
        gap: 40px;
        height: unset;
    }

    .pestChooseUsSection .content .contentSeparator .imgDiv {
        height: 500px;
        width: 500px;
    }

    .pestChooseUsSection .content .contentSeparator .imgDiv .aboutUs, .pestChooseUsSection .content .contentSeparator .imgDiv .toolsImg {
        height: 500px;
        width: 500px;
    }
}

@media (max-width: 820px) {  

    .pestChooseUsSection .content .contentSeparator .imgDiv {
        height: 400px;
        width: 400px;
    }

    .pestChooseUsSection .content .contentSeparator .imgDiv .aboutUs, .pestChooseUsSection .content .contentSeparator .imgDiv .toolsImg {
        height: 100%;
        width: 100%;
    }

    .pestChooseUsSection .content .contentSeparator button {
        font-size: 15px;
    }

    .pestChooseUsSection .content .contentSeparator .left {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
    }
    
    .pestChooseUsSection .content .contentSeparator .right {
        bottom: 0;
        right: 50%;
        transform: translateX(50%);
    }

    .pestChooseUsSection .content .rightContent {
        width: 80%;
    }
    
    .pestChooseUsSection .content .rightContent h2 {
        width: unset;
        text-align: center;
        font-size: 28px;
    }
    
    .pestChooseUsSection .content .rightContent h3 {
        width: 90%;
        align-self: center;
        font-size: 18px;
    }
    
    .pestChooseUsSection .content .rightContent div {
        width: 90%;
    }
}

@media (max-width: 450px) {  
    .pestChooseUsSection .content .contentSeparator .imgDiv {
        height: 250px;
        width: 250px;
    }

    .pestChooseUsSection .content .contentSeparator button {
        height: 30px;
        width: 220px;
        font-size: 11px;
    }

    .pestChooseUsSection .content .rightContent {
        gap: 10px;
        width: 325px;
    }
    
    .pestChooseUsSection .content .rightContent h2 {
        font-size: 28px;
    }
    
    .pestChooseUsSection .content .rightContent h3 {
        width: 250px;
    }
    
    .pestChooseUsSection .content .rightContent div {
        width: 325px;
    }
}