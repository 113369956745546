.pestServicesSection {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    background-image: url("../../images/service_bg.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    width: 100%;
}

.pestServicesSection .serviceCards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 50px;
    width: 70%;
}

